
import React from 'react';
import { Stack, Container, Row, Col } from 'react-bootstrap';
import DisplayTower from './modules/DisplayTower';

const Tech: React.FC = () => {
    const techs = [
        {
            headerColor: "accent-green",
            title: "Node & Typescript",
            items: [
                'Enterprise standard - highest level of quality, security, scalability, and reliability in our solutions, designed to meet a range of requirements',
                'Custom made apps - tailored high performing apps to match your exact needs',
                'Package development - expertly crafted software components or libraries ready to for distributed and/or installation',
                'Serverless - highly scalable solutions allowing for greater flexibility, scalability, and easier maintenance so that more time can be spent building what makes your solution unique.'
            ]
        },
        {
            headerColor: "accent-purple",
            title: "PHP",
            items: [
                'E-commerce Development - can build on a vast array of e-commerce platforms to get your business trading in no time',
                'Custom made apps - We have experience in a number of frameworks from different purposes.',
                'We have extensive experience migrating or porting complex projects in to current technologies ensuring you are up to date and current.',
                'Frameworks - Experience across popular frameworks such as Laravel, Symfony, Laminas and Slim',
                'Whether you run on Apache of Nginx, we have you covered and have built to a range of complex requirements',
            ]
        },
        {
            headerColor: "accent-red",
            title: "Database",
            items: [
                'Schema Design - it\'s important to get your data model right. It all starts here. We pride ourselves in creating schemas and data structures of the highest standard that stand the test of time.',
                'Optimisation - detect, improve and optimise. We have a proven method to solve any database optimisation issue which tells you categorically whether it is fully optimised.',
                'Migration strategies - wherever your database is and wherever it needs to be. We\'ve seen most of them and can help you get there safely and with minimal to no downtime.',
                'Experience with MySQL, Postgres and documented-based databases to name a few',
            ]
        },
        {
            headerColor: "primary",
            title: "DevOps",
            items: [
                'CI/CD - It\'s important to maintain high standards and have reliable deployments for CI/CD to be in place. Whether you have something existing or starting from scratch, we can get them running fast and reliably',
                'Automation - so many problems can benefit from automation. Whatever it is we can help.',
                'Infrastructure as code - We believe everything should be code',
                'Serverless - extensive experience writing and deploying serverless applications',
            ]
        },
        {
            headerColor: "secondary",
            title: "Across it all",
            items: [
                'Maintenance and support - the bit which is the least glamorous but the most important. Critical to ensuring your customers are happy and stay with you. We can advise of processes, tools and support structures to make sure this is as optimal as possible.',
                'Architecture & API Design - Before writing any code you need to know that the solutions makes sense. Taking an agile approach, we do enough design and architecture upfront to ensure a steady project will allow us to adapt to change.',
            ]
        },

    ]
    return (
        <Stack>
            <Container id='tech' className='my-5'>
                <h1 className='text-color--accent-red '>
                    Technologies
                    <span className='text-color--primary'>.</span>
                </h1>
                <Row>
                    {techs.map((tech) => (
                        <Col xs={12} md={6} className='my-2'>
                            <DisplayTower props={tech} />
                        </Col>
                    ))}
                </Row>
            </Container>
        </Stack>
    );
};

export default Tech;