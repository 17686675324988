
import React from 'react';
import { Stack, Container, Row, Col } from 'react-bootstrap';

import DisplaySquare from './modules/DisplaySquare';


const Services: React.FC = () => {
    const services = [
        {
            icon: "bi-bank",
            title: "Architecture",
            description: `Over a decade of experience designing and implementing complex systems with security,
            interoperability, ease of use and clear documentation at the heart of it all. With a key focus on
            simplicity and low maintenance, we have a wealth of experience in Serverless approaches. Additionally,
            we have many successes in carefully converting legacy applications in to a more distributed architecture.`
        },
        {
            icon: "bi-code-square",
            title: "Building Software",
            description: `Extensive full stack experience in building software to solve a vast array of problems.
            We support a range of popular languages. Technologies include Node, Typescript, React, PHP
            & Python.`
        },
        {
            icon: "bi-database",
            title: "Database",
            description: `Lots of experience designing database schemas and optimizing slow performing queries.
            Obsessed with making sure databases are running efficiently with full-proof methods to prove that they are.
            After we've looked at your databases, they'll be running smoothly.`
        },
        {
            icon: "bi-boxes",
            title: "DevOps",
            description: `Everything has to be code these days. Experience deploying to a range of environments for
            a number of company profiles. From Dev to Prod we've got the complete solution covered.`
        }
    ]
    return (
        <Stack>
            <Container id='services' className='my-5'>
                <h1 className='text-color--accent-red '>
                    What we have to offer
                    <span className='text-color--primary'>.</span>
                </h1>
                <Row>
                    {services.map((service) => (
                        <Col xs={12} md={6} className='my-2'>
                            <DisplaySquare props={service} />
                        </Col>
                    ))}
                </Row>

            </Container>
        </Stack>
    );
};

export default Services;
