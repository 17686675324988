
import { Container, Row, Col } from 'react-bootstrap';

interface DisplayTabletProps {
    props: DisplayTabletType,
}

type DisplayTabletType = {
    icon: string,
    description: string,
};

const DisplayTablet: React.FC<DisplayTabletProps> = ({ props }) => {
    return (
        <Container className='display-tablet'>
            <Row>
                <Col xs={3} sm={3} md={2} lg={1}>
                    <table className='full-height'>
                        <tbody>
                            <tr>
                                <td className="align-middle">
                                    <div className='circle'>
                                        <table className='full-height full-width'>
                                            <tbody>
                                                <tr>
                                                    <td className="align-middle">
                                                        <i className={props.icon}></i>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
                <Col>
                    <table className='full-height'>
                        <tbody>
                            <tr>
                                <td className="align-middle">{props.description}</td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
            </Row>
        </Container>
    );
};

export default DisplayTablet;