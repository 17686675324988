
import Container from 'react-bootstrap/Container';

interface DisplaySquareProps {
    props: DisplaySquareType,
}

type DisplaySquareType = {
    icon: string,
    title: string,
    description: string,
};

const DisplaySquare: React.FC<DisplaySquareProps> = ({ props }) => {
    return (
        <Container className='display-square'>
            <div><i className={props.icon}></i></div>
            <h2 className='my-4 text-color--accent-purple'>{props.title}</h2>
            <p>{props.description}</p>
        </Container>
    );
};

export default DisplaySquare;