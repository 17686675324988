
import React from 'react';
import { Stack, Container } from 'react-bootstrap';

const Home: React.FC = () => {
    return (
        <Stack className='background-color--secondary-gradient'>
            <Container id='home' className='my-5'>
                <h1 className='heading--hero text-color--primary'>Software.</h1>
                <h1 className='heading--hero text-color--accent-purple'>
                    Create<span className='text-color--primary'>.</span>
                    Build<span className='text-color--accent-red'>.</span>
                    Evolve<span className='text-color--accent-green'>.</span>
                </h1>
            </Container>
        </Stack>
    );
};

export default Home;