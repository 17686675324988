
import React from 'react';
import { Stack, Container, Row, Col } from 'react-bootstrap';
import DisplayTablet from './modules/DisplayTablet'


const About: React.FC = () => {
    const sellingPoints = [

            {
                icon: "bi-trophy",
                description: 'At Pixel 95 we can help design, build and architect your software with best in class solutions.'
            },
            {
                icon: "bi-rocket",
                description: 'We specialise in helping start ups, easily being able to adapt to change.'
            },
            {
                icon: "bi-infinity",
                description: 'Extensive experience - we have solved many problems and have provided great solutions.'
            },
            {
                icon: "bi-star",
                description: 'We pride our selves on the highest standards quality. We will make sure code stands the tests of time rather than just build you a solution to a price.'
            },
            {
                icon: "bi-arrows-expand",
                description: 'We are super flexible and truly believe in agile methodologies, we can adapt to changing requirements and build flexibility in to the solution.'
            },
            {
                icon: "bi-clock",
                description: 'Nobody likes a project being delivered late. We will strive to meet the deadlines and if anything unexpected arises will keep an open line of communication.'
            },
            {
                icon: "bi-shield-check",
                description: 'Honesty & Integrity - our two most important  values. It\'s important for you to know you will be dealing with people who just want to help you and get the most out of the project or solution.'
            }
    ]
    return (
        <Stack>
            <Container id='about' className='my-5'>
                <h1 className='text-color--accent-red '>
                    Why <span className='text-color--primary'>pixel</span><span className='text-color--accent-purple'>95</span>
                    <span className='text-color--primary'>?</span>
                </h1>
                <Row>
                    {sellingPoints.map((sellingPoint) => (
                        <Col xs={12} className='my-2'>
                            <DisplayTablet props={sellingPoint} />
                        </Col>
                    ))}
                </Row>

            </Container>
        </Stack>
    );
};

export default About;