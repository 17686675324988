import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Stack from 'react-bootstrap/Stack';
import NavBar from './components/NavBar';
import Home from './components/Home';
import About from './components/About';
import Tech from './components/Tech';
import Services from './components/Services';
import Contact from './components/Contact';

const App: React.FC = () => {
    return (
        <Stack>
            <NavBar />
            <Home />
            <Services />
            <Tech />
            <About />
            <Contact />
        </Stack>
    );
};
export default App;