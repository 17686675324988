import { Stack, Container, Nav, Navbar } from 'react-bootstrap';
import logo from './../logo.svg'

function NavBar() {
    return (
        <Stack className='sticky'>
            <Container>
                <Navbar collapseOnSelect expand="lg">
                    <Navbar.Brand href="#home">
                        <img
                            alt=""
                            src={logo}
                            width="170"
                            className="d-inline-block align-top"
                        />{' '}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav variant="fill" defaultActiveKey="1">
                                <Nav.Item className='text-end'>
                                    <Nav.Link eventKey="1" href="#home">
                                        Home
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='text-end'>
                                    <Nav.Link eventKey="2" href="#services">
                                        Services
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='text-end'>
                                    <Nav.Link eventKey="3" href="#tech">
                                        Technologies
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='text-end'>
                                    <Nav.Link eventKey="4" href="#about">
                                        About
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='text-end'>
                                    <Nav.Link eventKey="5" href="#contact">
                                        Contact
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Container>
        </Stack>
    );
}

export default NavBar;