
import { Container } from 'react-bootstrap';

interface DisplayTowerProps {
    props: DisplayTowerType,
}

type DisplayTowerType = {
    headerColor: string,
    title: string,
    items: Array<String>,
};

const DisplayTower: React.FC<DisplayTowerProps> = ({ props }) => {
    const completeClass = 'display-tower border-top--' + props.headerColor
    return (
        <Container className={completeClass}>
            <div>
                <h2 className='my-4 text-color--accent-purple'>{props.title}</h2>
            </div>
            <ul>
                {props.items.map((item) => (
                    <li>
                        {item}
                    </li>
                ))}
            </ul>
        </Container>
    );
};

export default DisplayTower;