
import React from 'react';
import { Stack, Container, Row, Form, Button, FloatingLabel } from 'react-bootstrap';


const Contact: React.FC = () => {
    return (
        <Stack>
            <Container id='contact' className='my-5'>
                <Row>
                    <h1 className='text-color--accent-red '>
                        Get in touch
                        <span className='text-color--primary'>.</span>
                    </h1>
                </Row>
                <Row>
                    <Form>
                        <>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Email address"
                                className="my-3"
                            >
                                <Form.Control type="email" placeholder="name@example.com" />
                            </FloatingLabel>
                            <FloatingLabel controlId="floatingPassword" label="Full Name" className="my-3">
                                <Form.Control type="text" placeholder="Full Name" />
                            </FloatingLabel>
                            <FloatingLabel controlId="floatingTextarea2" label="Message">
                                <Form.Control
                                    as="textarea"
                                    placeholder="Message"
                                    style={{ height: '100px' }}
                                />
                            </FloatingLabel>
                        </>
                        <Form.Group className="my-3" controlId="">
                            <Button className='full-width background-color--accent-green' variant="success">Send Message</Button>
                        </Form.Group>
                    </Form>
                </Row>
            </Container>
        </Stack>
    );
};

export default Contact;